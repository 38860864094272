

.ml-auto {
    margin-left: auto;
}

.ml-10 {
    margin-left: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-10 {
    padding-top: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pd-10 {
    padding: 10px;
}

.padding-0 {
    padding: 0 !important;
}

.mr-auto {
    margin-right: auto;
}

.mr-10 {
    margin-right: 10px !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-auto {
    margin-bottom: auto;
}

.flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.flex-no-wrap {
    flex-wrap: nowrap !important;
}

.flex-center-end {
    display: flex;
    justify-content: center;
    align-items: flex-end !important;
    flex-wrap: wrap;
}

.flex-between-start {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.gap-20 {
    gap: 20px;
}

.w-45 {
    width: 45%;
}

.w-30 {
    width: 30% !important;
}

.w-70 {
    width: 70% !important;
}

.w-inherit {
    width: inherit;
}

.clickable {
    cursor: pointer;
}

.bg-dark-gray {
    background: #444440 !important;
}

.preview-img {
    width: 40px !important;
    height: 40px !important;
}
